import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(relativePath: { eq: "projekte/silver_linings/image1.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(relativePath: { eq: "projekte/silver_linings/image2.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(relativePath: { eq: "projekte/silver_linings/image3.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(relativePath: { eq: "projekte/silver_linings/image4.jpg" }) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="Silver Linings"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/0fD6FJs5cn4"
            width="100%"
          />
          <div className="flex-1 py-32 mb-16">
            <h3>Silver Linings – Steve (Steffen Schulte-Lippern)</h3>
            <p>
              Ein Silberstreifen am Horizont in unsicheren Zeiten: Der Fotograf{' '}
              <b>Steve</b> hat für das Projekt <b>Silver Linings</b>{' '}
              Lüdenscheider Künstler und Kreative vor die Kamera geholt und sie
              an unterschiedlichsten Orten portraitiert. Ihre nachdenklichen
              Statements hat er in kurzen Sequenzen eingefangen. Entstanden ist
              ein leiser Film mit einer Mut machenden Aussage.
            </p>
            <div className="my-32">
              <GatsbyImage
                className="max-w-672"
                objectFit="contain"
                image={data.image1.childImageSharp.gatsbyImageData}
                alt="Silver Linings"
              />
            </div>
            <p>
              <b>Steve - ein Mann, ein Baum.</b>
              <br />
              Der Zwei-Meter Mann erinnert mit seiner Gesichtsfrisur eher an
              einen Holzfäller aus dem skandinavischen Hinterland. Er ist
              zwischen 30 und 40 Jahre alt, ein Typ zum Pferde stehlen, einer
              der anpackt und einer, der verdammt gut mit seinen Canons umgehen
              kann.
            </p>
            <div className="my-32">
              <GatsbyImage
                className="max-w-672"
                objectFit="contain"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="Silver Linings"
              />
            </div>
            <p>
              Besonders schätzenswert ist sein Humor, seine Schwarzmalerei, sein
              Durchhaltevermögen und der weiche Kern. Das Schaf im roten
              Wolfspelz ist in der Tat trinkfest, überspielt schlechte Launen
              gekonnt und liefert immer ab – erstklassig. Mutti würde sagen: „Er
              kann gut mit Menschen“ und ich sage: „Ein Pfundstyp, die
              Empfehlung Nummer 1, der geborene Lieblingsfotograf“.
            </p>
            <div className="my-32 flex">
              <GatsbyImage
                className="w-1/2 mr-16"
                objectFit="cover"
                image={data.image2.childImageSharp.gatsbyImageData}
                alt="Silver Linings"
              />
              <GatsbyImage
                className="w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="Silver Linings"
              />
            </div>
            <div className="pt-32">
              <p>
                <b>Mitwirkende</b>
                <br />
                Mattes Czech, Kreativdirektor von David&Goliath
              </p>
            </div>
            <div className="pt-32">
              <b>
                <a
                  href="https://www.studio-steve.de"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Zur Webseite des Künstlers
                </a>
              </b>
            </div>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Silver Linings</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description=" Ein Silberstreifen am Horizont in unsicheren Zeiten:
      Der Fotograf Steve hat für das Projekt Silver Linings
      Lüdenscheider Künstler und Kreative vor die Kamera geholt und sie
      an unterschiedlichsten Orten portraitiert. Ihre nachdenklichen
      Statements hat er in kurzen Sequenzen eingefangen. Entstanden ist
      ein leiser Film mit einer Mut machenden Aussage."
      publicURL={props.data.image1.publicURL}
      title="Silver Linings"
    />
  );
};

export default Page;
